.lindbacks-btn{
    

    @apply cursor-pointer inline-block relative bg-blue w-full rounded text-left px-6 py-6 text-white font-medium;

    

}


.lindbacks-btn::after{
    @apply mr-24 absolute h-full text-white;
    content: '';
    background-color: #D8D8D8;
    top:0;
    width: 1px;
    right: 0;
}


.lindbacks-btn::before{
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiI+PHBhdGggZD0iTTE4LjYyNSAxLjI3bDExLjIzIDExLjIzLTExLjIzIDExLjIzTTAgMTIuNWgyOS44NTUiLz48L2c+PC9nPjwvc3ZnPg==');
    @apply mr-8 bg-center absolute h-full w-6 bg-contain bg-no-repeat;;
    content: '';
    top:0;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.lindbacks-btn:hover::before{
    transform: translateX(18px);    
}
.lindbacks-btn:hover{
    background-color: #0467d3;    
}

